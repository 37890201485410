import React from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, mobileContentSidePadding } from '../styles/index'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/shared/SEO'
import {useGlobalComponentsQuery} from '../hooks/index'

export interface contactProps {
  data: {
    contentfulPageContact: {
      title: string
      seoTitle: string
      seoDescription: string
      heroDesktop: {
        fluid: string
      }
      topParagraph: {
        topParagraph: string
      }
      contacts: {}
    }
  }
}

const Contact = ({ data: { contentfulPageContact } }: contactProps) => {

  const {
    title,
    seoTitle,
    seoDescription,
    heroDesktop,
    topParagraph,
    contacts,
  } = contentfulPageContact

  const global = useGlobalComponentsQuery()

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={global?.seoImage.file.url}
      />
      <Wrapper leftContent={true}>
        <ContentWrapper>
          <div>
            <h6>{title}</h6>
          </div>
          <ParagraphWrapper>
            <p
              dangerouslySetInnerHTML={{ __html: topParagraph.topParagraph }}
            />
            {Object.values(contacts).map(
              (
                { title, email, paragraph, secondEmail, secondParagraph },
                index
              ) => (
                <EmailWrapper key={title + index}>
                  <p className="title">{title}</p>
                  <a className="emailDetails" href={`mailto:${email}`}>
                    {email}
                  </a>
                  <p className="emailDetails">{paragraph}</p>
                  {secondEmail && (
                    <>
                      <a className="emailDetails" href={`mailto:${email}`}>
                        {secondEmail}
                      </a>
                      <p className="emailDetails">{secondParagraph}</p>
                    </>
                  )}
                </EmailWrapper>
              )
            )}
          </ParagraphWrapper>
        </ContentWrapper>
        <ImgWrapper>
          <StyledImg
            fluid={heroDesktop.fluid}
            fadeIn={true}
            durationFadeIn={250}
          />
        </ImgWrapper>
      </Wrapper>
    </>
  );
}

export default Contact

const Wrapper = styled.section`
  display: flex;
  flex-direction: column-reverse;
  height: auto;
  width: 100vw;
  
  ${desktopBreakpoint}{
    flex-direction: ${({leftContent})=>leftContent ? 'row' : 'row-reverse'};
    height: auto;
  }
`;

const StyledImg = styled(Img)`

  ${desktopBreakpoint}{
    height: ${desktopVW(1000)};
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  background-color: white;
  height: auto;
  width: 100vw;
  position: relative;
  padding: ${mobileVW(100)} ${mobileContentSidePadding} ${mobileVW(40)} ${mobileContentSidePadding};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h6{
    font-family: 'messinaMono', "Courier New", Courier, monospace;
    font-weight: 400;
    letter-spacing: -0.05em;
    font-size: ${mobileVW(35)};
    width: 100%;
    text-align: center;

    ${desktopBreakpoint}{
      width: auto;
      text-align: left;
      font-size: ${desktopVW(30)};
    }
  }

  p, a{
    font-family: 'messinaMono', "Courier New", Courier, monospace;
    font-weight: 400;
    letter-spacing: -0.05em;
    line-height: 140%;
    color: ${colors.subtleGrey};
    
    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)};
      width: 100%;
    }
  }

  p{
    color: ${colors.lightGrey};
  }

  a{
    color: ${colors.subtleGrey};
  }

  .title{
    color: ${colors.black};
    font-size: ${mobileVW(23)};
    ${desktopBreakpoint}{
      font-size: ${desktopVW(18)};  
    }
  }

  .CollectionSidebar__subtitle{
    text-transform: uppercase;
    font-size: ${mobileVW(17)};
    padding-top: ${mobileVW(8)};
    ${desktopBreakpoint}{
      padding-top: ${desktopVW(5)}; 
      font-size: ${desktopVW(14)};
    }
  }

  ${desktopBreakpoint}{
    justify-content: flex-start;
    height: ${desktopVW(1000)};
    width: 35vw;
    padding: ${desktopVW(140)} ${desktopVW(62)} ${desktopVW(50)} ${desktopVW(62)};
  }
`;

const ImgWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: none;

  ${desktopBreakpoint}{
    display: block;
    height: 66.5vw;
    width: 70vw;
  }
`;

const ParagraphWrapper = styled.div`
  padding-top: ${mobileVW(60)};
  ${desktopBreakpoint}{
    padding-top: ${desktopVW(30)};
  }
`;

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${mobileVW(30)};

  .emailDetails{
    padding-top: ${mobileVW(5)};
    ${desktopBreakpoint}{
      padding-top: ${desktopVW(5)};
    }
  }


  ${desktopBreakpoint}{
    padding-top: ${desktopVW(30)};
  }
`;


export const ContactPageQuery = graphql`
  query ContactPage($id: String!) {
    contentfulPageContact(id: { eq: $id }) {
      title
      heroDesktop {
        fluid(maxWidth: 1600, quality: 50) {
          ...GatsbyContentfulFluid_withWebp_noBase64
          }
      }
      topParagraph {
        topParagraph
      }
      contacts {
        service {
          email
          paragraph
          title
        }
        business {
          email
          paragraph
          title
          secondEmail
          secondParagraph
        }
      }
    }
  }
`